import BaseButton from '../components/BaseButton';
import BaseLink from '../components/BaseLink';

const Software = () => {

  const onRequestClick = () => {
    const element = document.getElementById('questions')!
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <section id='benefits' className='relative flex flex-col justify-center items-center mb-3xl'>
      <div className='flex justify-between w-full pt-2xl m:flex-col m:gap-2xs'>
        <h2 className='text-h-2 text-primary uppercase ml-2xl tablet:text-h-3 m:ml-l sm:ml-0'>Software<br />test</h2>
        <p className='text-h-3 text-white max-w-[36.7rem] tablet:text-[30px] tablet:max-w-[28rem] m:ml-l sm:ml-0'>Our software is a way to a new level of financial management</p>
      </div>
      <p className='mt-xl text-body text-center max-w-3xl'> We don't just restore access to your wallet, we create endless possibilities</p>
      <div className='mt-xl flex flex-row flex-wrap gap-s justify-center'>
        {/* <BaseButton onClick={onRequestClick}>Leave a request</BaseButton> */}
        <BaseLink href='https://t.me/soft_crypto'>Leave a request</BaseLink>
        <BaseLink type='secondary' href='https://t.me/soft_crypto'>Join Telegram</BaseLink>
      </div>
    </section>
  );
};

export default Software;