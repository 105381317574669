import React from 'react';
import Layout from './components/layout/Layout';
import Hero from './blocks/Hero';
import Benefits from './blocks/Benefits';
import Price from './blocks/Price';
import Reviews from './blocks/Reviews';
import Software from './blocks/Software';
import Faq from './blocks/Faq';
import Questions from './blocks/Questions';

function App() {
  return (
    <Layout>
      <div className="bg-black text-white">
        <Hero />
        <Benefits />
        <Price />
        <Reviews />
        <Software />
        <Faq />
        <Questions />
      </div>
    </Layout>
  );
}

export default App;
