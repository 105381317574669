import review_1 from '../images/review_1.png';
import review_2 from '../images/review_2.png';
import review_3 from '../images/review_3.png';


const Reviews = () => {
  return (
    <section id='prices' className='relative flex flex-col mb-3xl reviews-layer '>
      <div className='absolute reviews-layer_gradient h-[25%] w-[40%]'></div>
      <h2 className='text-h-2 pl-xl text-with-bg mt-xl'>Reviews</h2>
      <div className='flex flex-row flex-wrap gap-xs justify-center mt-2xl'>
        {[review_1, review_2, review_3].map((review, idx) => (
          <img className='max-w-[22.6rem]' src={review} key={review + idx} alt={review + idx} />))}
      </div>
    </section>
  );
};

export default Reviews;