import React from 'react';
import BaseLink from '../components/BaseLink';

const Hero = () => {
  return (
    <section id='hero' className='relative pt-[12.5rem] pb-xl flex flex-col items-center'>
      <div className='absolute hero-layer h-[50%] w-[50%] top-[-6rem]'></div>
      <h1 className='text-h-1 tablet:text-h-2 xs:text-h-3 text-center max-w-6xl text-with-bg'>Access to your cryptocurrency wealth without restrictions!</h1>
      <BaseLink type='secondary' className='mt-xl font-medium' href='https://t.me/soft_crypto'>Join telegram</BaseLink>
    </section>
  );
};

export default Hero;