import btc from '../images/bitcoin.svg'
import eth from '../images/eth.svg'
import binance from '../images/binance.svg'
import all_coins from '../images/all_coins.svg'

type priceItem = {
  logo: string,
  coin: string,
  items: string[],
  price: string,
}

const prices: priceItem[] = [
  {
    logo: btc,
    coin: 'BTC',
    items: [
      'Technical support 24/7',
      'Easy installation',
      'Checking 200 million per day'
    ],
    price: '400$'
  },
  {
    logo: eth,
    coin: 'ETH',
    items: [
      'Technical support 24/7',
      'Easy installation',
      'Checking 200 million per day',
      'More profitable then BTC'
    ],
    price: '600$'
  },
  {
    logo: binance,
    coin: 'BINANCE',
    items: [
      'Technical support 24/7',
      'Easy installation',
      'Checking 200 million per day',
      'More profitable then BTC',
      'Checks all coins in BEP-20 and BEP-2 network'
    ],
    price: '800$'
  },
  {
    logo: all_coins,
    coin: 'ALL',
    items: [
      'Technical support 24/7',
      'Easy installation',
      'Checking 200 million per day',
      'More profitable then BTC',
      'Checks all coins in BEP-20 and BEP-2 network',
      'Checking NFTs (ERC-20 and BEP-20)'
    ],
    price: '1200$'
  }
]

const Price = () => {
  return (
    <section id='price' className='relative flex flex-col mb-2xl'>
      <h2 className='text-h-2 pl-xl text-with-bg'>Price</h2>
      <div className='price-layer mt-xl py-2xl'>

        <div className='flex flex-row flex-wrap gap-s justify-center'>
          {prices.map(({ logo, coin, items, price }) => (
            <div key={coin} className='price-card flex flex-col items-center justify-beetwen w-[17rem] h-[26rem] py-[2.2rem] px-[1.5rem]'>
              <div className='flex flex-col items-center flex-grow'>
                <img src={logo} alt={`${coin}_logo`} />
                <div className='mt-xs'>
                  {items.map((item, idx) => (<p className='price-card_item text-[0.85rem]' key={coin + idx}>{item}</p>))}
                </div>
              </div>
              <div className='text-[#2fd4a1] text-[2rem] font-semibold bg-[#142d5c] px-xs py-[0.5rem]'>{price}</div>
            </div>
          )
          )
          }
        </div>
      </div>

    </section>
  );
};

export default Price;