import React, { FC, ReactNode } from 'react';
import { Header } from "./Header";
import Footer from "./Footer";
import BaseLoader from "../BaseLoader";

interface LayoutProps {
  children: ReactNode
  loading?: boolean
}

const Layout: FC<LayoutProps> = ({ children, loading = false }) => {
  return (
    <>
      <div className="relative flex flex-col min-h-screen bg-black max-w-[1440px] mx-auto my-0 px-xl lg:px-l sm:px-2xs">
        {
          loading && (
            <div className="h-screen w-screen fixed bg-black opacity-70 grid place-content-center z-9999">
              <BaseLoader />
            </div>
          )
        }
        <Header />
        <div className="grow flex flex-col bg-black-light">
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;