import { FC, ReactNode, useEffect, useRef, useState } from "react"
import IconClose from "./icons/IconClose"
import ReactDOM from "react-dom";

interface BaseModalProps {
  id: string
  isOpened: boolean
  onClose?: () => void;
  children: ReactNode
}

const BaseModal: FC<BaseModalProps> = ({
  id,
  isOpened,
  onClose,
  children
}) => {
  const [isModalOpen, setModalOpen] = useState(isOpened);
  const modalRef = useRef<HTMLDialogElement | null>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };

  useEffect(() => {
    setModalOpen(isOpened);
  }, [isOpened]);

  useEffect(() => {
    const modalElement = modalRef.current;

    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isModalOpen]);

  return isModalOpen ? ReactDOM.createPortal(
    <dialog
      ref={modalRef}
      id={id}
      onKeyDown={handleKeyDown}
      className="fixed w-screen h-screen m-0 bg-[#0000008c] flex items-center justify-center"
    >
      <div className="relative max-w-xl max-h-[26.5rem] w-full h-full bg-black modal-layer">
        <button
          onClick={handleCloseModal}
          className="absolute top-[2rem] right-[4.25rem]"
        >
          <IconClose />
        </button>
        <>{children}</>
      </div>
    </dialog>, document.body
  ) : null
}

export default BaseModal
