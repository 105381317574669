import benefits_bg from "../images/benefits_bg.svg";

const benefits: JSX.Element[] = [
  <>Perfectly working software</>,
  <>Support for BTC | ETH | BNB</>,
  <>Over 700 satisfied clients</>,
  <>Free software updates</>,
  <>24/7 support</>,
  <>Profit without any risk</>,
]

const Benefits = () => {
  return (
    <section id='benefits' className='relative flex flex-col justify-center items-center mb-3xl'>
      <img className='absolute top-0' src={benefits_bg} alt='benefits_bg' />
      <div className='flex justify-between w-full pt-2xl m:flex-col m:gap-2xs'>
        <h2 className='text-h-2 text-primary uppercase ml-2xl tablet:text-h-3 m:ml-l sm:ml-0'>Crypto<br />soft</h2>
        <p className='text-h-3 text-white max-w-[36.7rem] tablet:text-[30px] tablet:max-w-[28rem] m:ml-l sm:ml-0'>Have you ever dreamed of a key that unlocks the cryptocurrency world? We have it!</p>
      </div>
      <p className='mt-xl text-body text-center max-w-3xl'>Our software is a way to a new level of financial management.<br /> We don't just restore access to your wallet, we create endless possibilities.</p>
      <div className='mt-[10.94rem] flex flex-row flex-wrap gap-s justify-center'>
        {benefits.map((benefit, idx) => (
          <div key={idx} className='benefit-card flex items-center justify-center w-[24.76rem] h-[12.5rem] py-xl'>
            <p className='text-with-bg text-h-3 font-medium text-center'>{benefit}</p>
          </div>
        )
        )
        }
      </div>
    </section>
  );
};

export default Benefits;