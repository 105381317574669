import BaseAccordion from '../components/BaseAccordion';
import benefits_bg from "../images/benefits_bg.svg";

const Faq = () => {
  return (
    <section id='faq' className='relative faq-layer flex flex-col items-center pb-3xl mb-s'>
      <h2 className='text-h-2 text-with-bg mt-xl m:text-h-3 m:px-2xs'>Frequently asked questions</h2>
      <div className='absolute -z-1 top-3xl right-0 blur-[5px]'>
        <img src={benefits_bg} alt='benefits_bg' />
      </div>
      <BaseAccordion
        className='mt-2xl px-2xs'
        items={[
          {
            id: 1,
            title: 'Why do you sell software and not make money yourself?',
            text: 'Initially I wrote the software not for sale. Back in 2020, 3 years ago, I used the software myself and some of my friends. But since my income has not dropped during this time, and wallets that exist in the world there are billions and becomes even more, I decided to earn even more and sell the software, it has absolutely no effect on my income from software, but also brings additional profit.'
          },
          {
            id: 2,
            title: 'What guarantees?',
            text: "I can't guarantee that you will find 10 bitcoins or pay off the program in 1 day. BUT I guarantee that if you don't find the wallets within a week, I will pay you back! "
          },
          {
            id: 3,
            title: 'Money out of thin air?',
            text: 'As for me, this is one of the most difficult moneys, as I spent a lot of time fixing bugs, writing code, etc. over these 3 years. I worked around the clock and spent many sleepless nights at the monitor. For me, this is a huge work done!'
          },
          {
            id: 4,
            title: 'Is it legal?',
            text: "Given that most wallets are old forgotten crypto wallets whose owners have most likely forgotten their accesses to them, I think legally. I compare it to looking for gold jewelry by the sea-coast, when people with a device walk around looking for lost rings, earrings, etc., no one can say it's illegal. I think it is the same here!"
          },
          {
            id: 5,
            title: 'Can I install it on my phone?',
            text: 'Yes, can you do it.'
          },
          {
            id: 6,
            title: 'How much a day will I earn?',
            text: 'All as individual as possible. Sometimes a day absolutely nothing finds, even a few days of silence can be, sometimes in a day finds 5-10 wallets at once.'
          },
        ]}
      />
    </section>
  );
};

export default Faq;
