import { FC } from "react";

interface IconArrowProps {
  type?: 'primary' | 'secondary'
  rotation?: 'up' | 'down' | 'left' | 'right'
  isHovered?: boolean
  className?: string
}

const IconArrow: FC<IconArrowProps> = ({ className, type = "primary", rotation = 'right', isHovered = false }) => {

  const stroke = {
    'primary': "#B9B9B9",
    'secondary': '#000000'
  }

  const hover = {
    'secondary': "#B9B9B9",
    'primary': '#000000'
  }

  const rotate = {
    'up': '-90deg',
    'down': '90deg',
    'left': '180deg',
    'right': '0deg',
  }

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none" style={{ rotate: rotate[rotation] }}>
      <path d="M1 1L7 7L1 13" stroke={isHovered ? hover[type] : stroke[type]} strokeWidth="2" strokeLinejoin="bevel" />
    </svg>
  );
}

export default IconArrow;