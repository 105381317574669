import logo_img from "../../images/logo.svg"
import IconTg from "../icons/IconTg";
import { generateLinks, headerLinks } from "./Header";

const Footer = () => {
  return (
    <footer id="footer" className="mt-2xl w-full bg-black flex items-center justify-center text-white py-l">
      <div className="max-w-[41rem] flex justify-between w-full sm:flex-col sm:gap-xs sm:pl-m sm:w-auto">
        <div>
          <img src={logo_img} alt='logo' />
        </div>
        <div className="flex flex-col justify-center gap-xs sm:mb-s">
          <p className="text-[#D1D1D1] opacity-40">Our contacts</p>
          <a href="https://t.me/soft_crypto" target="_blank" className="flex text-primary gap-[0.625rem]"><IconTg />soft_crypto</a>
          <a href="https://t.me/crpt_soft" target="_blank" className="flex text-primary gap-[0.625rem]"><IconTg />crpt_soft</a>
        </div>
        <div className="flex flex-col justify-center gap-xs">
          {generateLinks(headerLinks)}
        </div>
      </div>
    </footer>
  );
};

export default Footer;