import { FC } from "react";
import IconPlus from "./icons/IconPlus";
import benefits_bg from "../images/benefits_bg.svg";
import BaseButton from "./BaseButton";
import BaseLink from "./BaseLink";

interface MobileMenuProps {
  nav: JSX.Element[]
  onClose: () => void
}

const MobileMenu: FC<MobileMenuProps> = ({ nav, onClose }) => {

  const onRequestClick = () => {
    const element = document.getElementById('questions')!
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    onClose();
  }

  return (
    <div id="mobile-menu" className="top-0 left-0 flex-col justify-between bg-black fixed h-screen w-screen hidden p-2xs text-white">
      <div className="flex flex-col flex-grow">
        <div className="flex justify-between items-center mb-15">
          <span className="text-header-M">Menu</span>
          <button
            className="rotate-45 w-m"
            onClick={onClose}
          >
            <IconPlus />
          </button>
        </div>
        <div className="flex flex-col flex-grow justify-between">
          <div className="flex flex-col space-y-2xs pt-l">
            {nav}
          </div>
          {/* <BaseButton button_type='primary' className='font-medium mt-m' onClick={onRequestClick}>Leave a request</BaseButton> */}
          <BaseLink type='primary' className='font-medium mt-m' href='https://t.me/soft_crypto'>Leave a request</BaseLink>
          <img className='self-center mt-m' src={benefits_bg} alt='benefits_bg' />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;