import { AnchorHTMLAttributes, FC, useState } from "react";
import IconArrow from "./icons/IconArrow";

interface BaseLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  type?: 'primary' | 'secondary'
}

const BaseLink: FC<BaseLinkProps> = ({ type = 'primary', children, className, ...props }) => {

  const [isHovered, onHover] = useState(false)

  let style = ""
  if (type === 'primary') {
    style = "text-white hover:bg-primary hover:text-black"
  } else {
    style = "bg-primary text-black hover:bg-transparent hover:text-white"
  }

  return (
    <a
      onPointerEnter={() => onHover(true)}
      onPointerLeave={() => onHover(false)}
      className={`${className} ${style} border-primary rounded-[40px] border h-[3.625rem] px-[1.5rem] py-[1.125rem] tablet:px[1rem] tablet:py-[0.8rem] flex gap-2xs justify-center items-center transition hover:no-underline`}
      {...props}
    >
      {children}
      <IconArrow type={type} isHovered={isHovered} />
    </a>
  );
};

export default BaseLink;